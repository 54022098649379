.burger {
    width: 2rem;
    height: 2rem;
    margin-right: 3em;
    padding-bottom: 3em;
    z-index: 20;
    display: none;
  }
  
  @media (max-width: 768px) {
    .burger {
      display: flex;
      justify-content: space-around;
      flex-flow: column nowrap;
    }
  }
  
  .burger > div {
    width: 2rem;
    height: 0.25rem;
    background-color: #333;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
  
  .burger > div:nth-child(1) {
    transform: rotate(0);
  }
  
  .burger.open > div:nth-child(1) {
    transform: rotate(45deg);
  }
  
  .burger > div:nth-child(2) {
    transform: translateX(0);
    opacity: 1;
  }
  
  .burger.open > div:nth-child(2) {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .burger > div:nth-child(3) {
    transform: rotate(0);
  }
  
  .burger.open > div:nth-child(3) {
    transform: rotate(-45deg);
  }

/*
.header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto 1em auto;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background: rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border-bottom: 2px solid #ff0000;
    position: sticky;
    top: 0;
    z-index: 9;
}
.LOGO {
    margin: 0.3em 2em;
    width: 50px;
    height: 50px;
}
.hamburger-menu {
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 15px;
    right: 20px;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
} */

ul {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
}
li {
    display: inline-block;
}
li a {
    color: black;
    font-weight: 600;
    text-decoration: none;
}
li a:hover {
    color: #ff0000 ;
    transition: all .35s ease;
}

@media only screen and (max-width: 1400px) {
    .menu {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
}