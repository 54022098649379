.container-topnavbar-grid {
    height: 40px;
    max-width: 1000px;
    margin: 0.2em auto 0;
} 
.container-topnavbar-grid > small{
    margin-left: 1em;
}
.right {
    float: right;
    margin-right: 2em;
}
