.container-main-about {
    max-width: 1400px;
    margin: 2em auto;
    text-align: center;
}
.container-main-about > h1{
margin-bottom: 2em;
}
.container-about-both {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3em;
}
.container-about-crankybicycle > h2 {
    margin-bottom: 2em;
}
.container-about-crankybicycle-image{
    width: 300px;
    height: 300px;
}
.container-about-crankybicycle-bike {
    width: 400px;
    height: 280px;
    object-fit: cover;
}
.container-about-p{
    margin: 3em auto;
    width: 400px;
}
iframe {
    width: 460px;
    height: 260px;
}
@media only screen and (max-width: 1100px) {
    iframe {
        width: 410px;
        height: 230px;
    }
}
@media only screen and (max-width: 900px) {
    .container-about-both {
        display: grid;
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: 700px) {
    .container-about-p{
        margin: 3em auto;
        width: 320px;
    }
    .container-about-crankybicycle-bike {
        width: 300px;
        height: 205px;
        object-fit: cover;
    }
    iframe {
        display: block;
        margin: 0 auto;
        width: 320px;
        height: 180px;
    }


}