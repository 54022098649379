.carousel-image {
    display: block;
    margin: 0 auto;
    height: 400px;
    width: 1000px;
    max-width: 1400px;
    object-fit: cover;
    object-position: top;
}
@media only screen and (max-width: 1000px) {
    .carousel-image {
        height: 400px;
        width: 700px;
    }
}

@media only screen and (max-width: 700px) {
    .carousel-image {
        height: 300px;
        width: 100%;
    }
}