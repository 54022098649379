.container-main-article1{
    margin: 2em auto 1em auto;
    max-width: 1400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.container-main-video{
    margin: 0 auto;
}
.container-main-video > video{
    margin: 2.5em auto 0 auto;
    width: 500px;
    height: 300px;
    object-fit: cover;
}
.container-main-article1-desc{
    margin: 6em auto;
    width: 400px;
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000;
}
.container-info{
    display: block;
    height: 20px;
    background-image: linear-gradient(45deg, #f5b41c 25%, #a8a5a5 25%, #a8a5a5 50%, #f5b41c 50%, #f5b41c 75%, #a8a5a5 75%, #a8a5a5 100%);
    background-size: 56.57px 56.57px;
    max-width: 700px;
    margin: 0 auto 2em auto;
    border-radius: 50px;
}
.container-info > p {
    text-align: center;
    font-weight: bold;
    color: #000000;
}
.container-main-article2 > h2{
    text-align: center;
    width: 500px;
}
.container-main-article2{
    margin: 3em auto;
    width: 500px;
}
.container-article2-cards{
    max-width: 1400px;
    margin: 0 auto 5em auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.card {
    width: 450px;
    margin: 2em auto;
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 3px 3px 10px -5px rgb(0, 0, 0); 
}
.image {
    object-fit: cover;
    margin: 0 auto;
    width: 450px;
    height: 340px;
}
.desc {
    text-align: center;
}
.desc > p, h3 {
    padding: 0.4em 0;
}
@media only screen and (max-width: 1400px) {
    .container-article2-cards{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 1000px) {
    .container-article2-cards{
        grid-template-columns: 1fr;
    }
    .container-main-article1 {
        grid-template-columns: 1fr;
    }
    .container-main-video > video{
        width: 400px;
        height: 200px;
    }
    .container-info{
        height: 48px;
        padding-top: 0.1em;
    }
    .container-info > p{
        padding-top: 0.1em;
    }
    .container-main-article2{
        width: 400px;
    }
    .container-main-article2 > h2{
        width: 300px;
    }
    .card {
        width: 400px;
    }
    .image {
        width: 400px;
    }
}
@media only screen and (max-width: 600px) {
    .container-main-article1-desc{
        width: 350px;
        margin: 1em auto;
    }
    .container-main-video > video{
        width: 360px;
        height: 200px;
    }
    .card {
        width: 360px;
    }
    .image {
        width: 360px;
    }
    .container-main-article2{
        width: 300px;
    }
    .container-main-article2 > p{
        padding-top: 2em;

    }
}
