.footer-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 auto;
    max-width: 1400px;
    background: rgba(0, 0, 0, 0.08);
    color: rgb(0, 0, 0);
}
.footer-contacts {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    margin: 1em 0;

}
.footer-soc {
    display: flex; 
    justify-content: center; 
}
.footer-soc > li {
    margin-right: 20px;
}
.footer-c{
    text-align: center;
    font-weight: bold;
}
@media only screen and (max-width: 1000px) {
    .footer-menu{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 700px) {
    .footer-soc > li {
        margin-right: 7px; 
    }
}