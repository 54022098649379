.container-main-bicycles {
    max-width: 1400px;
    margin: 2em auto;
    text-align: center;
}
.container-main-bicycles-desc {
    margin: 2em;
    width: 400px;
    border-top: 2px solid #ff0000;
}
.container-main-bicycles-desc > a {
    color: #ff0000;
}

@media only screen and (max-width: 600px) {
    .container-main-bicycles-desc {
        margin: 1em;
        width: 340px;
        border-top: 2px solid #ff0000;
    }
}
